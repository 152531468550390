import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  KubernetesClusterErrorProvider,
  useKubernetesClusterError,
} from "../KubernetesClusterErrorContext/KubernetesClusterErrorContext";
import { WarningPanel } from '@backstage/core-components';
import {KubernetesClusterNavigation} from "./KubernetesClusterNavigation";
import { useParams } from 'react-router';
import sections from "./sections";

const ContentGrid = () => {
  const { error } = useKubernetesClusterError();
  let { section } = useParams();
  if (!section || !sections[section]) {
    section = 'overview';
  }
  
  return (
    <>
      <Grid container>
        {error && (
          <Grid item xs={12}>
            <WarningPanel title="Error loading Kubernetes Cluster Plugin">
              <Typography>{error}</Typography>
            </WarningPanel>
          </Grid>
        )}
        <Grid item xs={2}>
          <KubernetesClusterNavigation />
        </Grid>
        <Grid item xs={10}>
          {React.createElement(sections[section])}
        </Grid>
      </Grid>
    </>
  );
};

/**
 *
 *
 * @public
 */
export const KubernetesClusterContent = () => {
  return (
    <KubernetesClusterErrorProvider>
      <ContentGrid />
    </KubernetesClusterErrorProvider>
  );
};
