import {DiscoveryApi, FetchApi} from "@backstage/core-plugin-api";
import { ResponseError } from '@backstage/errors';
import {ConfigEditorApi} from "../api/ConfigEditorApi";
import {ConfigSchemaApi, ConfigSchemaResult} from "@backstage/plugin-config-schema";
import {Observable} from "@backstage/types/index";
import ObservableImpl from 'zen-observable';
import {JsonObject} from "@backstage/types";

export class ConfigEditorClient implements ConfigEditorApi, ConfigSchemaApi {
  private discoveryApi: DiscoveryApi;
  private fetchApi: FetchApi;
  
  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }
  
  schema$(): Observable<ConfigSchemaResult> {
    return new ObservableImpl(subscriber => {
      this.getSchemas().then(
        // @ts-ignore
        schema => subscriber.next({ schema }),
        error => subscriber.error(error),
      );
    });
  }
  
  async getApiOrigin(): Promise<string> {
    return await this.discoveryApi.getBaseUrl('config-editor');
  }
  
  async getSchemas(): Promise<string> {
    const apiOrigin = await this.getApiOrigin();
    const res = await this.fetchApi.fetch(`${apiOrigin}/schema`);
    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }
    
    return await res.json();
  }
  
  async getForm(): Promise<string> {
    const apiOrigin = await this.getApiOrigin();
    const res = await this.fetchApi.fetch(`${apiOrigin}/form`);
    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }
    
    return await res.json();
  }
  
  async update(config: JsonObject): Promise<string> {
    const apiOrigin = await this.getApiOrigin();
    const res = await this.fetchApi.fetch(`${apiOrigin}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(config),
    });
    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }
    
    return await res.json();
  }
  
}
