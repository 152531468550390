import {createApiRef} from "@backstage/core-plugin-api";
import {JsonObject} from "@backstage/types";

export interface ConfigEditorApi {
  getSchemas(): Promise<string>;
  getForm(): Promise<string>;
  update(config: JsonObject): Promise<string>;
}

export const configEditorApiRef = createApiRef<ConfigEditorApi>({
  id: 'plugin.config-editor.service',
});