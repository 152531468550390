import {
  createPlugin,
  createRouteRef,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

export const rootCatalogKubernetesClusterRouteRef = createRouteRef({
  id: 'tmatic-kubernetes-cluster',
});

export const kubernetesClusterPlugin = createPlugin({
  id: 'tmatic-kubernetes-cluster',
  apis: [],
  routes: {
    entityContent: rootCatalogKubernetesClusterRouteRef,
  },
});

/**
 * Props of EntityKubernetesContent
 *
 * @public
 */
export type EntityKubernetesClusterContentProps = {};

/**
 * Props of EntityKubernetesContent
 *
 * @public
 */
export const EntityKubernetesClusterContent: (
  props: EntityKubernetesClusterContentProps,
) => JSX.Element = kubernetesClusterPlugin.provide(
  createRoutableExtension({
    name: 'EntityKubernetesClusterContent',
    component: () => import('./Router').then(m => m.Router),
    mountPoint: rootCatalogKubernetesClusterRouteRef,
  }),
);
