import {createApiRef} from "@backstage/core-plugin-api";

export interface SecretManagerApi {
  getApiOrigin(): Promise<string>;
  createSecret(secret: object): Promise<string>;
  listSecrets(): Promise<string>;
}

export const secretManagerApiRef = createApiRef<SecretManagerApi>({
  id: 'plugin.secret-manager.service',
});