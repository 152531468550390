import {
  useKubernetesClusterError
} from "../../KubernetesClusterErrorContext/KubernetesClusterErrorContext";
import React, {useCallback, useEffect} from "react";
import {useEntity} from "@backstage/plugin-catalog-react";
import {useNodes} from "../hooks";
import {INode} from "kubernetes-models/v1";
import {
  Table,
  TableColumn,
} from '@backstage/core-components';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const defaultColumns: TableColumn<INode>[] = [
  {
    title: 'Name',
    highlight: true,
    width: 'auto',
    render: (node: INode) => {
      return node.metadata?.name;
    }
  },
  {
    title: 'CPU',
    highlight: true,
    width: 'auto',
    render: (node: INode) => {
      return node.status?.capacity?.cpu;
    }
  },
  {
    title: 'Memory',
    highlight: true,
    width: 'auto',
    render: (node: INode) => {
      return node.status?.capacity?.memory;
    }
  },
  {
    title: 'Disk',
    highlight: true,
    width: 'auto',
    render: (node: INode) => {
      return node.status?.capacity?.["ephemeral-storage"];
    }
  },
  {
    title: 'Taint',
    highlight: true,
    width: 'auto',
    render: (node: INode) => {
      return node.spec?.taints?.length;
    }
  },
  {
    title: 'Roles',
    highlight: true,
    width: 'auto',
    render: (node: INode) => {
      return node.metadata?.labels?.['kubernetes.io/role'];
    }
  },
  {
    title: 'Version',
    highlight: true,
    width: 'auto',
    render: (node: INode) => {
      return node.status?.nodeInfo?.kubeletVersion;
    }
  },
  {
    title: 'Age',
    highlight: true,
    width: 'auto',
    render: (node: INode) => {
      const creationTimestamp = new Date(node.metadata?.creationTimestamp ?? 0);
      const now = new Date();
      const ageInSeconds = Math.floor((now.getTime() - creationTimestamp.getTime()) / 1000);
      if (ageInSeconds < 60) {
        return `${ageInSeconds}s`;
      } else if (ageInSeconds < 3600) {
        const ageInMinutes = Math.floor(ageInSeconds / 60);
        return `${ageInSeconds}s / ${ageInMinutes}m`;
      } else if (ageInSeconds < 86400) {
        const ageInMinutes = Math.floor(ageInSeconds / 60);
        const ageInHours = Math.floor(ageInMinutes / 60);
        return `${ageInSeconds}s / ${ageInMinutes}m / ${ageInHours}h`;
      } else {
        const ageInMinutes = Math.floor(ageInSeconds / 60);
        const ageInHours = Math.floor(ageInMinutes / 60);
        const ageInDays = Math.floor(ageInHours / 24);
        return `${ageInDays}d`;
      }
    }
  },
  {
    title: 'Conditions',
    highlight: true,
    width: 'auto',
    render: (node: INode) => {
      return node.status?.conditions?.filter(condition => condition.type === 'Ready').map(condition => condition.type);
    }
  }
];

export const Nodes = () => {
  const classes = useStyles();
  const { entity } = useEntity();
  const { value, error, loading } = useNodes({
    clusterName: entity.metadata.name,
  });
  const { setError } = useKubernetesClusterError();
  const setErrorCallback = useCallback(setError, [setError]);
  useEffect(() => {
    if (error) {
      setErrorCallback(error.message);
    }
  }, [error, setErrorCallback]);
  
  return (
    <Table
      title="Nodes"
      options={{ paging: true, search: false, emptyRowsWhenPaging: false }}
      isLoading={!value && loading}
      data={value?.items ?? []}
      emptyContent={
        <div className={classes.empty}>
          {error !== undefined ? 'Error loading nodes' : 'No nodes found'}
        </div>
      }
      columns={defaultColumns}
    />
  );
};