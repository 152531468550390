import {createApiRef} from "@backstage/core-plugin-api";
import {Entity} from "@backstage/catalog-model";

export interface FormContext {
  uiSchema: object;
  schema: object;
  formData: object;
}

export interface EntityManagerApi {
  createEntity(entity: Entity): Promise<Entity>;
}

export const entityManagerApiRef = createApiRef<EntityManagerApi>({
  id: 'plugin.entity-manager.service',
});