import {EntityManagerApi} from "../../api/EntityManager";
import {DiscoveryApi, FetchApi} from "@backstage/core-plugin-api";
import {ResponseError} from "@backstage/errors";
import {Entity} from "@backstage/catalog-model";

export class EntityManagerClient implements EntityManagerApi {
  private discoveryApi: DiscoveryApi;
  private fetchApi: FetchApi;
  
  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }
  
  private async getApiOrigin(): Promise<string> {
    return await this.discoveryApi.getBaseUrl('catalog');
  }
  
  async createEntity(entity: Entity): Promise<Entity> {
    const apiOrigin = await this.getApiOrigin();
    const res = await this.fetchApi.fetch(`${apiOrigin}/entity-manager/entities`, {
      method: 'POST',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }
    return res.json();
  }
  
  
  
  
}
