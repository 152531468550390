import { JSX } from "react";
import {Pods} from "./Pods";
import {ClusterOverview} from "./ClusterOverview";
import { Nodes } from "./Nodes";

const sections: {[key: string]: () => JSX.Element} = {
  'pods': Pods,
  'overview': ClusterOverview,
  'nodes': Nodes
}

export default sections;