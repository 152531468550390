import React from 'react';
import { Entity } from '@backstage/catalog-model';
import {
  useEntity,
  MissingAnnotationEmptyState,
} from '@backstage/plugin-catalog-react';
import { Route, Routes } from 'react-router-dom';
import { ANNOTATION_KUBERNETES_API_SERVER } from '@backstage/plugin-kubernetes-common';
import { KubernetesClusterContent } from './components/KubernetesClusterContent';

export const isKubernetesClusterAvailable = (entity: Entity) => Boolean(entity.metadata.annotations?.[ANNOTATION_KUBERNETES_API_SERVER]);

export const Router = () => {
  const { entity } = useEntity();

  if (isKubernetesClusterAvailable(entity)) {
    return (
      <Routes>
        <Route path="/" element={<KubernetesClusterContent />}>
          <Route path=":section" element={<KubernetesClusterContent />} />
        </Route>
      </Routes>
    );
  }

  return (
    <>
      <MissingAnnotationEmptyState
        annotation={ANNOTATION_KUBERNETES_API_SERVER}
      />
    </>
  );
};
