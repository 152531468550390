import {
  createApiFactory,
  createPlugin,
  createRoutableExtension, discoveryApiRef, fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {ConfigEditorClient} from "./client/ConfigEditorClient";
import {configEditorApiRef} from "./api/ConfigEditorApi";
import {configSchemaApiRef} from "@backstage/plugin-config-schema";

export const configEditorPlugin = createPlugin({
  id: 'config-editor',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: configEditorApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new ConfigEditorClient({
          discoveryApi,
          fetchApi
        }),
    }),
    createApiFactory({
      api: configSchemaApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new ConfigEditorClient({
          discoveryApi,
          fetchApi
        }),
    }),
  ],
});

export const ConfigEditorPage = configEditorPlugin.provide(
  createRoutableExtension({
    name: 'ConfigEditorPage',
    component: () =>
      import('./components/ConfigEditorComponent').then(m => m.ConfigEditorComponent),
    mountPoint: rootRouteRef,
  }),
);
