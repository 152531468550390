import React  from "react";
import {List, ListItem, ListItemText, Collapse, makeStyles} from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
}));

type navItem = {
  key: string;
  label: string;
  items?: navItem[];
}

const navigation: navItem[] = [
  {
    key: 'overview',
    label: "Overview",
  },
  {
    key: 'applications',
    label: "Applications",
  },
  {
    key: 'nodes',
    label: "Nodes",
  },
  {
    key: 'workloads',
    label: "Workloads",
    items: [
      {
        key: 'workloads_overview',
        label: "Overview"
      },
      {
        key: 'pods',
        label: "Pods"
      },
      {
        key: 'deployments',
        label: "Deployments"
      },
      {
        key: 'daemon_sets',
        label: "Daemon Sets"
      },
      {
        key: 'replica_sets',
        label: "Replica Sets"
      },
      {
        key: 'replication_controllers',
        label: "Replication Controllers"
      },
      {
        key: 'jobs',
        label: "Jobs"
      },
      {
        key: 'cron_jobs',
        label: "Cron Jobs"
      },
    ],
  },
  {
    key: 'config',
    label: "Config",
    items: [
      {
        key: 'config_maps',
        label: "Config Maps"
      },
      {
        key: 'secrets',
        label: "Secrets"
      },
      {
        key: 'resource_quotas',
        label: "Resource Quotas"
      },
      {
        key: 'limit_ranges',
        label: "Limit Ranges"
      },
      {
        key: 'horizontal_pod_autoscalers',
        label: "Horizontal Pod Autoscalers"
      },
      {
        key: 'pod_disruption_budgets',
        label: "Pod Disruption Budgets"
      },
      {
        key: 'priority_classes',
        label: "Priority Classes"
      },
      {
        key: 'runtime_classes',
        label: "Runtime Classes"
      },
      {
        key: 'leases',
        label: "Leases"
      },
      {
        key: 'mutating_webhook_configurations',
        label: "Mutating Webhook Configurations"
      },
      {
        key: 'validating_webhook_configurations',
        label: "Validating Webhook Configurations"
      },
    ]
  },
  {
    key: 'network',
    label: "Network",
    items: [
      {
        key: 'services',
        label: "Services"
      },
      {
        key: 'endpoints',
        label: "Endpoints"
      },
      {
        key: 'ingresses',
        label: "Ingresses"
      },
      {
        key: 'ingress_classes',
        label: "Ingress Classes"
      },
      {
        key: 'network_policies',
        label: "Network Policies"
      },
      {
        key: 'ingress_controllers',
        label: "Pod Forwarding"
      }
    ]
  },
  {
    key: 'storage',
    label: "Storage",
  },
  {
    key: 'namespaces',
    label: "Namespaces",
  },
  {
    key: 'events',
    label: "Events",
  },
  {
    key: 'helm',
    label: "Helm",
  },
  {
    key: 'access_control',
    label: "Access Control",
  },
  {
    key: 'crd',
    label: "Custom Resources",
  }
];



export const KubernetesClusterNavigation = () => {
  const classes = useStyles();
  
  const navigate = useNavigate();
  
  const [open, setOpen] = React.useState<{[key: string]: boolean }>({});
  const handleClick = (key: string) => {
    setOpen(prevState => ({
        ...prevState,
        [key]: !prevState[key]
    }));
  };
  
  function CompositeItem(props: {item: navItem}) {
    return (
      <>
        <ListItem button onClick={() => handleClick(props.item.key)}>
          <ListItemText primary={props.item.label}/>
          {props.item.items?.length && (open[props.item.key] ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {props.item.items && props.item.items.map((subItem) => (
          <Collapse in={open[props.item.key]} timeout="auto" unmountOnExit>
            <List dense={true} component="div" disablePadding>
              <Item item={subItem} className={classes.nested} />
            </List>
          </Collapse>
        ))}
      </>
    )
  }
  
  function Item (props: {item: navItem, className?: string}) {
    return (
      <ListItem button onClick={() => {navigate(props.item.key)}} className={props.className}>
        <ListItemText primary={props.item.label}/>
      </ListItem>
    )
  }
  
  return (
    <List
      dense={true}
      disablePadding={true}
      component="nav"
      className={classes.root}
    >
      {navigation.map((item) => {
        if (item.items?.length) {
          return <CompositeItem item={item} />
        }
        return <Item item={item} />
      })}
    </List>
  )
};