import useAsync from 'react-use/esm/useAsync';

import { useApi } from '@backstage/core-plugin-api';
import { kubernetesApiRef } from '@backstage/plugin-kubernetes-react';
import { PodList } from 'kubernetes-models/v1';

/**
 * Arguments for useApiResources
 *
 * @public
 */
export interface usePodsOptions {
  clusterName: string;
}

/**
 * Retrieves Pods for a cluster
 *
 * @public
 */
export const usePods = ({ clusterName }: usePodsOptions) => {
  const kubernetesApi = useApi(kubernetesApiRef);
  return useAsync(async () => {
    return await kubernetesApi
      .proxy({
        clusterName,
        path: '/api/v1/pods?limit=500',
      })
      .then(r => {
        return r.json() as Promise<PodList>;
      });
  }, [clusterName]);
};
