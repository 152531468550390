import {ScmIntegrationsApi,scmIntegrationsApiRef,ScmAuth} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  gitlabAuthApiRef,
  googleAuthApiRef,
  microsoftAuthApiRef, OAuthApi,
  oauthRequestApiRef, oktaAuthApiRef, oneloginAuthApiRef,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi
} from '@backstage/core-plugin-api';
import {OAuth2} from "@backstage/core-app-api";
import {kubernetesAuthProvidersApiRef, KubernetesAuthProviders} from "@backstage/plugin-kubernetes-react";
import {
  AksKubernetesAuthProvider,
  GoogleKubernetesAuthProvider,
  KubernetesAuthProvider, OidcKubernetesAuthProvider,
  ServerSideKubernetesAuthProvider
} from "@backstage/plugin-kubernetes";

export const tmaticOIDCAuthApiRef: ApiRef<
  OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'auth.tmatic',
});


// @ts-ignore
class MyKubernetesAuthProviders extends KubernetesAuthProviders {
  private readonly kubernetesAuthProviderMap: Map<
    string,
    KubernetesAuthProvider
  >;
  
  constructor(options: {
    microsoftAuthApi: OAuthApi;
    googleAuthApi: OAuthApi;
    oidcProviders?: {
      [key: string]: OpenIdConnectApi;
    };
  }) {
    super(options);
    
    this.kubernetesAuthProviderMap = new Map<string, KubernetesAuthProvider>();
    this.kubernetesAuthProviderMap.set(
      'google',
      new GoogleKubernetesAuthProvider(options.googleAuthApi),
    );
    this.kubernetesAuthProviderMap.set(
      'serviceAccount',
      new ServerSideKubernetesAuthProvider(),
    );
    this.kubernetesAuthProviderMap.set(
      'googleServiceAccount',
      new ServerSideKubernetesAuthProvider(),
    );
    this.kubernetesAuthProviderMap.set(
      'aws',
      new ServerSideKubernetesAuthProvider(),
    );
    this.kubernetesAuthProviderMap.set(
      'azure',
      new ServerSideKubernetesAuthProvider(),
    );
    this.kubernetesAuthProviderMap.set(
      'tmatic.azure',
      new ServerSideKubernetesAuthProvider(),
    );
    this.kubernetesAuthProviderMap.set(
      'tmatic.aws',
      new ServerSideKubernetesAuthProvider(),
    );
    this.kubernetesAuthProviderMap.set(
      'tmatic.gcp',
      new ServerSideKubernetesAuthProvider(),
    );
    this.kubernetesAuthProviderMap.set(
      'localKubectlProxy',
      new ServerSideKubernetesAuthProvider(),
    );
    this.kubernetesAuthProviderMap.set(
      'aks',
      new AksKubernetesAuthProvider(options.microsoftAuthApi),
    );
    
    if (options.oidcProviders) {
      Object.keys(options.oidcProviders).forEach(provider => {
        this.kubernetesAuthProviderMap.set(
          `oidc.${provider}`,
          new OidcKubernetesAuthProvider(
            provider,
            options.oidcProviders![provider],
          ),
        );
      });
    }
  }
}


export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: kubernetesAuthProvidersApiRef,
    deps: {
      gitlabAuthApi: gitlabAuthApiRef,
      googleAuthApi: googleAuthApiRef,
      microsoftAuthApi: microsoftAuthApiRef,
      oktaAuthApi: oktaAuthApiRef,
      oneloginAuthApi: oneloginAuthApiRef,
    },
    factory: ({
                gitlabAuthApi,
                googleAuthApi,
                microsoftAuthApi,
                oktaAuthApi,
                oneloginAuthApi,
              }) => {
      const oidcProviders = {
        gitlab: gitlabAuthApi,
        google: googleAuthApi,
        microsoft: microsoftAuthApi,
        okta: oktaAuthApi,
        onelogin: oneloginAuthApi,
      };
      
      return new MyKubernetesAuthProviders({
        microsoftAuthApi,
        googleAuthApi,
        oidcProviders,
      });
    },
  }),
  createApiFactory({
    api: tmaticOIDCAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OAuth2.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'tmatic',
          title: 'TMatic',
          icon: () => null,
        },
        environment: configApi.getOptionalString('auth.environment'),
        defaultScopes: ['openid', 'profile', 'email'],
        popupOptions: {
          // optional, used to customize login in popup size
          size: {
            fullscreen: false,
          },
          /**
           * or specify popup width and height
           * size: {
           width: 1000,
           height: 1000,
           }
           */
        },
      }),
  }),
  ScmAuth.createDefaultApiFactory(),
];