import {
  createApiFactory,
  createPlugin,
  createRoutableExtension, discoveryApiRef, fetchApiRef,
} from '@backstage/core-plugin-api';
import {entityRouteRef, rootRouteRef} from './routes';
import {entityManagerApiRef} from "./api/EntityManager";
import {EntityManagerClient} from "./client/EntityManager";

export const entityManagerPlugin = createPlugin({
  id: 'entity-manager',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: entityManagerApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new EntityManagerClient({
          discoveryApi,
          fetchApi
        }),
    }),
  ]
});

export const EntityManagerPage = entityManagerPlugin.provide(
  createRoutableExtension({
    name: 'EntityManagerPage',
    component: () => import('./components/FormPage').then(m => m.FormPage),
    mountPoint: entityRouteRef,
  }),
);
