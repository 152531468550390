import { SecretManagerApi } from "@tmatic/plugin-secret-manager-react";
import {DiscoveryApi, FetchApi} from "@backstage/core-plugin-api";
import { Config } from '@backstage/config';
import { ResponseError } from '@backstage/errors';


export class SecretManagerClient implements SecretManagerApi {
  public configApi: Config;
  public discoveryApi: DiscoveryApi;
  private fetchApi: FetchApi;
  
  constructor(options: {
    configApi: Config;
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.configApi = options.configApi;
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }
  
  async getApiOrigin(): Promise<string> {
    return await this.discoveryApi.getBaseUrl('secret-manager');
  }

  async createSecret(_secret: object): Promise<string> {
    return 'secret-id';
  }
  
  async listSecrets(): Promise<string> {
    const apiOrigin = await this.getApiOrigin();
    const request = await this.fetchApi.fetch(`${apiOrigin}/secrets`);
    if (!request.ok) {
      throw await ResponseError.fromResponse(request);
    }
    
    return await request.json();
  }
  
}
