import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { secretManagerApiRef } from '@tmatic/plugin-secret-manager-react';
import {SecretManagerClient} from "./client";

export const secretManagerPlugin = createPlugin({
  id: 'secret-manager',
  apis: [
    createApiFactory({
      api: secretManagerApiRef,
      deps: {
        configApi: configApiRef,
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ configApi, discoveryApi, fetchApi }) =>
        new SecretManagerClient({
          configApi,
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const SecretManagerPage = secretManagerPlugin.provide(
  createRoutableExtension({
    name: 'SecretManagerPage',
    component: () => import('./components/ExampleComponent').then(m => m.ExampleComponent),
    mountPoint: rootRouteRef,
  }),
);
