import {
  useKubernetesClusterError
} from "../../KubernetesClusterErrorContext/KubernetesClusterErrorContext";
import React, {useCallback, useEffect} from "react";
import {useEntity} from "@backstage/plugin-catalog-react";
import {IPod} from "kubernetes-models/v1";
import {
  Table,
  TableColumn,
} from '@backstage/core-components';
import {makeStyles} from "@material-ui/core";
import {usePods} from "../hooks/usePods";

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const defaultColumns: TableColumn<IPod>[] = [
  {
    title: 'Name',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      return pod.metadata?.name;
    }
  },
  {
    title: 'Namespace',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      return pod.metadata?.namespace;
    }
  },
  {
    title: 'Containers',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      return pod.spec?.containers?.length;
    }
  },
  {
    title: 'CPU',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      
      return pod.spec?.containers?.reduce((acc, container) => {
        // @ts-ignore
        return acc + (container.resources?.requests?.cpu ?? 0);
      }, 0);
    }
  },
  {
    title: 'Memory',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      return pod.spec?.containers?.reduce((acc, container) => {
        // @ts-ignore
        return acc + (container.resources?.requests?.memory ?? 0);
      }, 0);
    }
  },
  {
    title: 'Restarts',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      return pod.status?.containerStatuses?.reduce((acc, container) => {
        return acc + (container.restartCount ?? 0);
      }, 0);
    }
  },
  {
    title: 'Controller By',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      return pod.metadata?.ownerReferences?.[0]?.name;
    }
  },
  {
    title: 'Node',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      return pod.spec?.nodeName;
    }
  },
  {
    title: 'QoS',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      return pod.status?.qosClass;
    }
  },
  {
    title: 'Age',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      const creationTimestamp = new Date(pod.metadata?.creationTimestamp ?? 0);
      return creationTimestamp.toLocaleDateString();
    }
  },
  {
    title: 'Status',
    highlight: true,
    width: 'auto',
    render: (pod: IPod) => {
      return pod.status?.phase;
    }
  }
];

export const Pods = () => {
  const classes = useStyles();
  const { entity } = useEntity();
  const { value, error, loading } = usePods({
    clusterName: entity.metadata.name,
  });
  const { setError } = useKubernetesClusterError();
  const setErrorCallback = useCallback(setError, [setError]);
  useEffect(() => {
    if (error) {
      setErrorCallback(error.message);
    }
  }, [error, setErrorCallback]);
  
  return (
    <Table
      title="Pods"
      options={{ paging: true, search: false, emptyRowsWhenPaging: false }}
      isLoading={!value && loading}
      data={value?.items ?? []}
      emptyContent={
        <div className={classes.empty}>
          {error !== undefined ? 'Error loading pods' : 'No pods found'}
        </div>
      }
      columns={defaultColumns}
    />
  );
};